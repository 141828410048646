module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3_tpr6bjxjyjnzufovxw4fetmvyu/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"display":"minimal-ui","icon":"./src/images/lakotaIcon.png","name":"Lakota People's Law Project Giving Center","short_name":"Lakota PLP GC","start_url":"/","background_color":"#BA2B26","theme_color":"#BA2B26","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cbe529ce72fa4bc1db19a38edcc65465"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3.1_react@18.3.1__react@18.3.1__moglmpdmtq4v6tonrt7povu3ly/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
